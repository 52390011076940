<!--
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-09 09:46:07
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-26 14:20:18
-->
<template>
  <div class="home">
    <div class="loading" v-if="!mapFinished">
      <!-- <Loading size="24px">加载中...</Loading> -->
      <div style="margin-bottom: 70px">
        <img src="@/assets/loading.gif" />
        <p>地图加载中...</p>
      </div>
    </div>
    <div class="home__container">
      <div class="choice">
        <ul class="con" ref="navRef">
          <li
            v-for="(item, index) of tabList"
            :key="index"
            :title="item.title"
            :class="{ on: item.selected }"
            @click="handleTabItem(item)"
          >
            {{ item.label }}
          </li>
        </ul>
        <div class="search" @click="handleSearchItem">
          <img src="@/assets/search.png" />
        </div>
      </div>
      <div class="container" :style="mapStyle">
        <div id="mapContainer" :style="mapStyle"><!--地图--></div>
      </div>
      <!--操作按钮-->
      <!-- <div class="lu" @click="showRecommendPath = true"> -->
      <div class="lu" @click="goPlan">
        <img src="@/assets/lu.png" title="open" />
      </div>
      <div class="lu bak" @click="handleBackHome">
        <img src="@/assets/comebak.png" />
      </div>
      <div class="opts">
        <!--附近-->
        <div class="icon nearby" @click="nearBySwitch = true">
          <img src="@/assets/fu.png" />
        </div>
        <!--自动讲解-->
        <div class="icon autoVoice" @click="handleAutoIntro">
          <img src="@/assets/speech1.png" v-if="autoIntro" title="open" />
          <img src="@/assets/speech.png" v-else title="close" />
        </div>
        <!--当前位置-->
        <div class="icon dang" @click="handleCurrentLocation">
          <img src="@/assets/dang.png" />
        </div>
      </div>
      <div
        class="cover"
        id="cover"
        v-if="nearBySwitch"
        @click="nearBySwitch = false"
      >
        <!--弹出层-->
        <div class="tipMsg"></div>
        <div class="shaiCon three" id="fuCon">
          <div class="con">
            <h5>前往附近以下哪个地点?</h5>
            <div id="fuContent">
              <div
                class="item"
                v-for="(item, index) of poiListNearBy"
                :key="index"
                :title="item.label"
                @click="handleNearByItem(item)"
              >
                <img class="icon" :src="item.icon" />
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="sPointContainer" v-if="showVoicePop">
        <!--点位详情 折叠的效果-->
        <div class="play" @click="handlePlayVoice">
          <img v-if="!isPlayVoice" src="@/assets/tizhibf@2x.png" title="play" />
          <img v-else src="@/assets/yinp_bf@2x.gif" title="play" />
        </div>
        <div class="name" @click="handleDetail">
          <span>{{ currentPoiInfo.name || '景点名称' }}</span>
          <img class="icon-right" src="@/assets/icon-right.png" />
        </div>
        <img
          class="close"
          src="@/assets/icon-close.png"
          @click="handlePauseVoice"
        />
      </div>
      <div class="routeline" v-if="distancePop">
        <!--推荐路线, 显示时间 距离 开始导航-->
        <div class="title">
          推荐路线
          <span @click="handleCancelPath">取消</span>
        </div>
        <div class="item time">
          约<span class="spendTime">{{ distanceInfo.spendTime }}</span
          >分钟
        </div>
        <div class="item">
          <span class="distance">{{ distanceInfo.distance }}</span
          >m
        </div>
        <!--<a href="./guide/index.html?analogType=1" class="guide">开始导航</a>-->
        <!-- <a class="guide" @click="handleNavigation">开始导航</a> -->
      </div>
    </div>
    <!-- 路线弹窗 -->
    <div
      class="cover"
      v-if="showRecommendPath"
      @click="showRecommendPath = false"
    >
      <!--弹出层-->
      <div class="lines" id="lines">
        <!--精品路线-->
        <div class="title">
          <img src="@/assets/luxiantj@2x.png" /> 精品路线推荐
        </div>
        <div class="con">
          <div
            class="item"
            v-for="(item, index) of recommendPath"
            :key="index"
            @click="handleRecommendItem(item)"
          >
            {{ index + 1 }}.{{ item.routeName }}
            <span
              ><i>{{ item.pointNum }}</i
              >个景点</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- poi -->
    <div class="poi" v-if="currentPoi">
      <div
        class="poi-click"
        ref="poi"
        :style="POIStyleObj"
        v-if="currentPoiInfo"
      >
        <div class="titleimg">
          <img :src="currentPoiInfo.imageUrl" />
        </div>
        <div class="TTimg">
          <div class="imgtitle" @click="handleDetail">
            <div style="display: flex">
              <span class="title">{{ currentPoiInfo.name }}</span>
              <span class="personNum"></span>
            </div>
            <div
              class="spot"
              v-if="
                currentPoiInfo.spotProAttractions &&
                currentPoiInfo.spotProAttractions.openStartTime
              "
            >
              {{ currentPoiInfo.spotProAttractions.openStartTime }} -
              {{ currentPoiInfo.spotProAttractions.openEndTime }}
            </div>
            <div
              class="spot"
              v-else-if="
                currentPoiInfo.spotProAttractions &&
                currentPoiInfo.spotProAttractions.openHours
              "
            >
              {{
                currentPoiInfo.spotProAttractions.openHours | replaceUnderline
              }}
            </div>
          </div>
          <div class="bottoms">
            <div
              class="item voice"
              @click="
                handlePlayVoice(currentPoiInfo.spotProAttractions || null)
              "
              v-if="selectType === 'scenic'"
            >
              <img v-if="!isPlayVoice" src="@/assets/bofang.png" />
              <img v-else src="@/assets/zanting.png" />
              <span>听讲解</span>
            </div>
            <div class="item gothere" @click="handleDistance">
              <img src="@/assets/gothere.png" />
              <span>去这里</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { loadMapLayerList, queryRouters, getLatelySpot } from '@/api/index'
import { Toast, Loading } from 'vant'
import mixinsMap from '@/utils/map'
import scenicInfo from '@/utils/scenicName'
import { checkURL } from '@/utils/validate'

export default {
  name: 'Home',
  mixins: [mixinsMap],
  components: {
    Loading,
  },
  data() {
    return {
      currentTab: '景点',
      tabList: [
        { label: '景点', title: '景点', selected: true },
        { label: '卫生间', title: '卫生间', selected: false },
        { label: '出入口', title: '出入口', selected: false },
        { label: '餐厅', title: '餐饮', selected: false },
        { label: '酒店', title: '住宿', selected: false },
        { label: '购物', title: '购物', selected: false },
        { label: '售票处', title: '售票点', selected: false },
      ],
      poiListNearBy: [
        {
          label: '卫生间',
          icon: require('@/assets/wc1@2x.png'),
          layerKey: 'restroom',
        },
        {
          label: '购物',
          icon: require('@/assets/gouwu1@2x.png'),
          layerKey: 'shopping',
        },
        {
          label: '停车场',
          icon: require('@/assets/tingcc1@2x.png'),
          layerKey: 'p',
        },
        {
          label: '餐厅',
          icon: require('@/assets/canting1@2x.png'),
          layerKey: 'restaurant',
        },
        {
          label: '住宿',
          icon: require('@/assets/jiudian1@2x.png'),
          layerKey: 'hotel',
        },
        {
          label: '出入口',
          icon: require('@/assets/damen1@2x.png'),
          layerKey: 'exit',
        },
      ],
      mapStyle: {},
      nearBySwitch: false,
      mapFinished: false,
      autoIntro: false,
      showRecommendPath: false,
      allPoiList: [],
      currentPoiList: {},
      poiList: [],
      markerList: [],
      currentPoi: null,
      currentPoiInfo: null,
      POIStyleObj: {},
      soundObj: null,
      mp3Obj: null,
      recommendPath: [],
      isPlayVoice: false,
      showVoicePop: false,
      distancePop: false,
      distanceInfo: {},
      voiceFile: null,
      routeInfo: null,
      isSingleScenic: null,
      isSearch: false,
      selectType: null,
      minLevel: 0,
    }
  },
  filters: {
    replaceUnderline(str) {
      return str.replace(/_/g, ' ')
    },
  },
  created() {
    const { scenicCode, scenicName, keywords, id, isSearch } = this.$route.query
    this.scenicCode = scenicCode || 'S001'
    this.scenicName = scenicName || '石燕湖'
    this.keywords = keywords || null
    this.isSingleScenic = parseInt(id) || null
    this.isSearch = isSearch || false
    this.wxConfig()
    this.getPoiList()
  },
  mounted() {
    this.importJmapJS()
  },
  beforeDestroy() {
    this.map = null
    clearInterval(this.time)
  },
  methods: {
    dealWithMapInfo() {
      this.map.on('EVENT_MAP_INIT_FINISH', () => {
        this.mapFinished = true
        this.addJmapMarker()
        this.soundObj = JMap.getSoundObj() || null
        // this.setTestLocation()
        this.time = setInterval(() => {
          this.throttle(this.getLocationFromWx())
        }, 2000)
        const level = this.map.getZoomSection()
        this.minLevel = level[0]
        console.log('level:', level)
        // this.map.setCenter(['113086616','28005675'])
        // this.map.setZoom(16);
      })
      this.map.on('EVENT_MAP_POI_CLICK', (markerObj) => {
        this.currentPoi =
          markerObj !== undefined && markerObj !== 0 ? markerObj : null
        if (markerObj !== undefined && markerObj !== 0) {
          this.isPlayVoice = false
          this.soundObj.pause('TYPE_SOUND_SPOT')
          const height = window.screen.availHeight
          this.mapStyle = {
            height: `${height}px`,
          }
          this.mp3Obj && this.mp3Obj.pause()
          this.showVoicePop = false
          this.isPlayVoice = false
          this.distancePop = false
          this.map.clearRouteInfo()
          this.currentPoiInfo = markerObj.setting.poiObj.details
          const pos = this.map.lnglatToScrPixel_Client(markerObj.setting.lnglat)
          console.log('pos:', pos, this.currentPoiInfo)
          console.log('haveTime:', markerObj.setting.poiObj)
          const maxWidth = this.selectType === 'scenic' ? 280 : 200 //pos.x
          // const leftX = this.selectType === 'scenic' ? 64 : maxWidth / 2 - 2
          const leftX = maxWidth / 2 - 2
          // const top = markerObj.setting.poiObj.haveTime ? 85 : 75
          this.POIStyleObj = {
            display: 'flex',
            left: `${leftX}px`,
            top: `0px`,
            width: `${maxWidth}px`,
          }
          this.$nextTick(() => {
            const itemHeight = this.$refs.poi.offsetHeight
            const itemWidth = this.$refs.poi.offsetWidth
            this.POIStyleObj = {
              display: 'flex',
              left: `${pos.x - leftX}px`,
              top: `${pos.y - (itemHeight + 5)}px`,
              width: `${maxWidth}px`,
            }
          })
          if (
            this.currentPoiInfo.spotProAttractions &&
            this.currentPoiInfo.spotProAttractions.voiceFile
          ) {
            this.voiceFile = this.currentPoiInfo.spotProAttractions.voiceFile
          } else if (
            this.currentPoiInfo.spotProAttractions &&
            this.currentPoiInfo.spotProAttractions.interpret
          ) {
            this.voiceFile = this.currentPoiInfo.spotProAttractions.interpret
          } else {
            this.voiceFile = null
          }
        }
        console.log('markerObj:', markerObj)
      })
      this.map.on('EVENT_MAP_MOVE_MAP', () => {
        if (this.currentPoi) {
          const pixel = this.map.lnglatToScrPixel_V2(
            this.currentPoi.setting.lnglat
          )
          console.log('EVENT_MAP_MOVE_MAP pixel:', pixel)
          const maxWidth = this.selectType === 'scenic' ? 280 : 200
          const top = this.selectType === 'scenic' ? 70 : 85
          // const maxWidth = this.selectType === 'scenic' ? 270 : 200 //pos.x
          const leftX = maxWidth / 2 - 2
          this.POIStyleObj = {
            display: 'flex',
            left: `${pixel.x / 2 - leftX}px`,
            top: `${pixel.y / 2 - top}px`,
            width: `${maxWidth}px`,
          }
          this.$nextTick(() => {
            const top = this.$refs.poi.offsetHeight + 5
            this.POIStyleObj = {
              display: 'flex',
              left: `${pixel.x / 2 - leftX}px`,
              top: `${pixel.y / 2 - top}px`,
              width: `${maxWidth}px`,
            }
          })
        }
      })
      this.map.on('EVNET_MAP_GUIDANCE', () => {
        console.log('语音播报完毕')
        this.isPlayVoice = false
      })
      JMap.on('J_EVENT_AUDIO', (type, status) => {
        console.log('type, status', type, status)
        if (status === 'PLAY_END') {
          this.isPlayVoice = false
        }
      })
    },
    getPoiList() {
      this.currentPoiList = {}
      this.allPoiList = []
      this.poiList = []
      this.tabList = []
      loadMapLayerList(this.scenicCode).then((res) => {
        console.log('loadMapLayerList res:', res, this.keywords)
        const { data } = res
        const { mapLayerVos } = data
        mapLayerVos.filter((item, index) => {
          // console.log('index:', index, this, this.currentTab)
          this.tabList.push({
            key: item.layerKey,
            label: item.layerName,
            title: item.layerName,
            selected: this.keywords
              ? this.keywords === item.layerKey
              : index === 0
              ? true
              : false,
          })
          item.layerDefaultIcon = checkURL(item.layerDefaultIcon)
            ? item.layerDefaultIcon
            : `${process.env.VUE_APP_IMAGEURL}${item.layerDefaultIcon}`
          item.layerFocusIcon = checkURL(item.layerFocusIcon)
            ? item.layerFocusIcon
            : `${process.env.VUE_APP_IMAGEURL}${item.layerFocusIcon}`
          item.spotProVos.filter((sItem) => {
            sItem.imageUrl = checkURL(sItem.imageUrl)
              ? sItem.imageUrl
              : `${process.env.VUE_APP_IMAGEURL}${sItem.imageUrl}`
            return sItem
          })
          if (
            (this.keywords && this.keywords === item.layerKey) ||
            index === 0
          ) {
            this.selectType = item.layerKey
            this.currentTab = item.layerName
            this.currentPoiList = item
            this.poiList = item.spotProVos
          }
          //  else {
          //   if (index === 0) {
          //     this.currentTab = item.layerName
          //     this.currentPoiList = item
          //     this.poiList = item.spotProVos
          //   }
          // }

          this.poiListNearBy.filter((pItem) => {
            pItem.label =
              item.layerKey === pItem.layerKey ? item.layerName : pItem.label
            return pItem
          })

          return item
        })
        this.allPoiList = mapLayerVos
        if (this.mapFinished) {
          this.addJmapMarker()
        }
      })
      queryRouters({ scenicCode: this.scenicCode }).then((res) => {
        console.log('queryRouters:', res)
        const { data } = res
        this.recommendPath = data
      })
    },
    addJmapMarker() {
      let pIndex = null
      this.markerList = []
      this.poiList.map((poiItem, index) => {
        let haveTime = false
        if (
          poiItem.spotProAttractions &&
          (poiItem.spotProAttractions.openStartTime ||
            poiItem.spotProAttractions.openHours)
        ) {
          haveTime = true
        }
        const markerObj = new JMap.Marker({
          name: poiItem.name,
          lnglat: new JMap.LngLat(poiItem.lng, poiItem.lat),
          icon: this.currentPoiList.layerDefaultIcon || poiItem.imageUrl,
          showLevel: this.isSearch ? this.minLevel : poiItem.showLevel,
          size: new JMap.Size(100, 68),
          offset: new JMap.Pixel(35, 60),
          iconC: this.currentPoiList.layerFocusIcon || poiItem.imageUrl,
          sizeC: new JMap.Size(100, 82),
          offsetC: new JMap.Pixel(36, 61),
          visible: true,
          poiObj: {
            type: '0',
            poiId: this.map.getScenicObjectId(),
            typePs: '',
            details: poiItem,
            haveTime: haveTime,
          },
          mapObj: this.map,
        })
        if (poiItem.id === this.isSingleScenic) {
          pIndex = index
        }
        this.markerList.push(markerObj)
      })
      this.map.showCustomPoiList(this.markerList, false, true, true)
      if (this.isSingleScenic) {
        this.map.clickByMarkerObj(
          this.markerList[pIndex],
          this.poiList[pIndex].showLevel
        )
      }
    },
    handleTabItem(item) {
      console.log('item:', item)
      this.selectType = item.key
      this.map.clearRouteInfo()
      this.map.clearPoiSearch()
      this.map.clearCustomPoiList()
      this.currentPoi = null
      this.currentTab = item.label
      this.tabList.filter((tItem) => {
        tItem.selected = item.label === tItem.label ? true : false
        return tItem
      })
      this.allPoiList.map((sItem) => {
        if (sItem.layerName == item.title) {
          this.currentPoiList = sItem
          this.poiList = sItem.spotProVos
          this.addJmapMarker()
        }
      })
    },
    handleDetail() {
      wx.miniProgram.navigateTo({
        url: '/pages/guide/scenicDetail/index?id=' + this.currentPoiInfo.id,
      })
    },
    goPlan () {
      wx.miniProgram.navigateTo({
        url: `/pages/guide/plan/index`,
      })
    },
    handleRecommendItem(item) {
      item.layerDefaultIcon = this.currentPoiList.layerDefaultIcon
      item.layerFocusIcon = this.currentPoiList.layerFocusIcon
      item.scenicCode = this.scenicCode
      item.scenicName = this.scenicName
      // const info = JSON.stringify(item)
      this.$router.push({
        path: '/path',
        query: {
          scenicCode: this.scenicCode,
          scenicName: this.scenicName,
          ids: item.id,
          isLocation: true,
        },
      })
    },

    handleBackHome() {
      wx.miniProgram.reLaunch({ url: '/pages/home/index' })
    },
    handleSearchItem() {
      wx.miniProgram.navigateTo({
        url: `/pages/guide/search/index?type=${this.currentTab}`,
      })
    },
    handlePlayVoice() {
      this.showVoicePop = true
      this.isPlayVoice = !this.isPlayVoice
      if (this.voiceFile && this.voiceFile.length !== 0) {
        if (/.mp3$/.test(this.voiceFile) && this.isPlayVoice) {
          // this.mp3Obj.play()
          this.soundObj.playMP3(this.voiceFile)
        } else if (/.mp3$/.test(this.voiceFile) && !this.isPlayVoice) {
          // this.mp3Obj.pause()
          this.soundObj.pause('TYPE_SOUND_SPOT')
        } else {
          if (this.isPlayVoice) {
            this.soundObj.play(this.voiceFile, 'TYPE_SOUND_SPOT', false)
          } else {
            this.soundObj.pause('TYPE_SOUND_SPOT')
          }
        }
      }
    },
    handlePauseVoice() {
      this.showVoicePop = false
      this.isPlayVoice = false
      // this.mp3Obj.pause()
      this.soundObj.stop('TYPE_SOUND_SPOT')
    },
    handleAutoIntro() {
      this.autoIntro = !this.autoIntro
      const text = this.autoIntro ? '自动语音讲解开启' : '自动语音讲解关闭'
      if (this.autoIntro) {
        this.map.setGuidanceSwitch(this.autoIntro)
      }
      this.soundObj.play(text, 'TYPE_SOUND_SPOT', false)
    },
    handleCancelPath() {
      this.map.clearRouteInfo()
      this.distancePop = false
    },
    handleNearByItem(item) {
      this.nearBySwitch = false
      const cur = this.map.getCarPos()
      getLatelySpot({
        lat: cur.lat,
        lng: cur.lng,
        layerKey: item.layerKey,
        scenicCode: this.scenicCode,
      }).then((res) => {
        this.markerList = []
        const { data } = res
        data.layerFocusIcon = checkURL(data.layerFocusIcon)
          ? data.layerFocusIcon
          : `${process.env.VUE_APP_IMAGEURL}${data.layerFocusIcon}`
        data.layerDefaultIcon = checkURL(data.layerDefaultIcon)
          ? data.layerDefaultIcon
          : `${process.env.VUE_APP_IMAGEURL}${data.layerDefaultIcon}`
        data.imageUrl = checkURL(data.imageUrl)
          ? data.imageUrl
          : `${process.env.VUE_APP_IMAGEURL}${data.imageUrl}`
        const markerObj = new JMap.Marker({
          name: data.name,
          lnglat: new JMap.LngLat(data.lng, data.lat),
          icon: data.layerDefaultIcon,
          size: new JMap.Size(100, 68),
          offset: new JMap.Pixel(35, 60),
          iconC: data.layerFocusIcon,
          sizeC: new JMap.Size(100, 82),
          offsetC: new JMap.Pixel(36, 61),
          visible: true,
          poiObj: {
            type: '0',
            poiId: this.map.getScenicObjectId(),
            typePs: '',
            details: data,
          },
          mapObj: this.map,
        })
        this.markerList.push(markerObj)
        this.map.showCustomPoiList(this.markerList, false, true, true)
        this.map.clickByMarkerObj(this.markerList[0])
      })
      // const placesearch = new JMap.PlaceSearch({
      //   pageIndex: 1,
      //   pageSize: 1,
      //   map: this.map,
      // })
      // const nearByObj = {
      //   keyword: item.label,
      //   type: '',
      //   poiCnt: 1,
      //   pageIndex: 1,
      // }
      // const that = this
      // placesearch.search(nearByObj, function (status, searchResult) {
      //   console.log('搜索结果:', searchResult, status)
      //   that.map.clearPoiSearch() //清除 之前的搜索结果
      //   that.map.clearCustomPoiList() //清除 语音点
      //   if (searchResult.poiList) {
      //     let nearbyPoi = []
      //     nearbyPoi.push(searchResult.poiList.pois[0])
      //     that.map.showPoiSearch(nearbyPoi, 1, 1)
      //   } else {
      //     Toast('这附近没有找到哦~')
      //   }
      // })
    },
    handleDistance() {
      console.log('this.currentPoiInfo:', this.currentPoiInfo)
      const { scenicId, lat, lng, id } = this.currentPoiInfo
      const drivingObj = new JMap.Driving({ map: this.map })
      const cur = this.map.getCarPos()
      const posList = [
        {
          poiId: { scenicId: 0, objdectId: 0 },
          lnglat: { lng: cur.lng, lat: cur.lat },
        },
        {
          poiId: { scenicId: scenicId, objdectId: id },
          lnglat: { lng: lng, lat: lat },
        },
      ]
      drivingObj.search(
        {
          type: 6,
          planCond: {},
          fileName: '',
          armList: { armCount: 2, poiList: posList },
        },
        (status, searchResult) => {
          if (status === 'complete') {
            if (!searchResult) {
              Toast('当前位置不在景区内')
            }
            const routeInfo = searchResult.guidePath[0]
            const distance = routeInfo.totalInfo.walkDis
            const spendTime = Math.ceil(routeInfo.totalInfo.walkTime / 60)
            this.distanceInfo = { distance, spendTime }
            this.routeInfo = routeInfo
            this.map.showRouteInfo(routeInfo)
            this.distancePop = true
            this.currentPoiInfo = null
          } else {
            Toast('当前位置不在景区内')
          }
        }
      )
      // console.log('this.currentPoiInfo:', this.currentPoiInfo)
    },
    handleNavigation() {
      sessionStorage.setItem('state', JSON.stringify(this.routeInfo))
      this.$router.push({
        path: '/guide',
        // query: { routerInfo: JSON.stringify(this.routeInfo) },
      })
    },
    handleCurrentLocation() {
      this.getLocationFromWx()
      if (this.currentLocationInfo.length !== 0) {
        this.map.setCenter(this.currentLocationInfo)
      }
    },
  },
}
</script>
 
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      display: block;
      width: 90px;
      height: 90px;
    }
    p {
      font-size: 16px;
      color: #ccc;
    }
  }
  &__container {
    width: 100%;
    height: 100%;
  }
  .choice {
    width: 100%;
    height: 0.8rem;
    padding: 0.15rem 0;
    box-sizing: border-box;
    // overflow: hidden;
    // overflow-x: scroll;
    .con {
      display: block;
      // width: 590px;
      // width: calc(100% + 1.18rem);
      height: 0.5rem;
      font-size: 0;
      padding: 0 1rem 0 0.2rem;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        color: transparent;
      }
      li {
        display: inline-block;
        font-size: 0.25rem;
        line-height: 0.5rem;
        padding: 0 0.2rem;
        margin: 0 0.2rem;
        color: #000;
        font-size: 0.28rem;
        border: 0;
        border-radius: 0.25rem;
      }
      .on {
        font-size: 0.3rem;
        background-color: #fd7d6f;
        color: #fff;
      }
    }
  }
  .search {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.18rem;
    height: 0.8rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .container {
    position: absolute;
    color: #000;
    border: 0px solid #000;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  #mapContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .lu {
    width: 2.14rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 1rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .bak {
    width: 1.5rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 2rem;
  }
  .opts {
    position: absolute;
    right: 0.1rem;
    bottom: 1rem;
    .icon {
      width: 0.88rem;
      height: 0.88rem;
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .cover {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .shaiCon {
      width: 100%;
      height: auto;
      h5 {
        margin: 0.2rem 0;
        padding-left: 0.2rem;
        text-align: center;
        font-weight: normal;
        font-size: 0.3rem;
      }

      .con {
        width: 5.5rem;
        height: 5.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        padding: 0.4rem;
        box-sizing: border-box;
        margin: -2.75rem 0 0 -2.75rem;
        z-index: 99;
      }
      .close {
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        right: 0.3rem;
        top: 0.4rem;
        img {
          display: block;
          width: 100%;
        }
      }
      .item {
        float: left;
        width: 25%;
        padding: 0.2rem;
        box-sizing: border-box;
        height: auto;
        overflow: hidden;
        text-align: center;
        font-size: 0.24rem;
        white-space: nowrap;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .three {
      .close {
        top: 1.6rem;
      }
      .item {
        width: 33.33%;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .lines {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      /*height: 5rem;*/
      background: #fff;
      .con {
        height: 3.6rem;
        overflow-y: scroll;
        span {
          float: right;
          color: #999;
          i {
            color: rgb(253, 125, 111);
            margin-right: 0.05rem;
            font-style: normal;
          }
        }
      }
      .item {
        border-bottom: solid 1px rgb(237, 237, 237);
        height: 1.2rem;
        overflow: hidden;
        font-size: 0.28rem;
        line-height: 1.2rem;
        padding: 0rem 0.3rem;
        color: rgb(102, 102, 102);
      }
      .title {
        color: rgb(51, 51, 51);
        font-size: 0.36rem;
        height: 1.2rem;
        line-height: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.3rem;
        border-bottom: solid 1px #ededed;
        img {
          float: left;
          display: block;
          width: 0.39rem;
          height: 0.35rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.poi {
  display: inline-block;
  width: auto;
  // position: relative;
  z-index: 10;
  .poi-click {
    width: auto;
    padding: 0.2rem 0.2rem;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0.2rem;
    font-size: 0;
    position: absolute;
    // margin-top: -0.3rem;
    // margin-left: -1.5rem;
    display: none;
    flex-direction: row;
    // top: -30rem;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 0.18rem solid transparent;
      border-right: 0.18rem solid transparent;
      border-top: 0.26rem solid #fff;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -0.09rem;
    }
  }
  .titleimg {
    // width: 1rem;
    // height: 1rem;
    // margin: 0.1rem 0.2rem 0 0.1rem;
    img {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // width: 100%;
      // height: 100%;
    }
  }

  .titleimg,
  .TTimg {
    // float: left;
    display: inline-block;
  }
  .imgtitle {
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
    .title {
      padding-left: 0.15rem;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.28rem;
    width: 1.68rem;
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    overflow: hidden;
    color: #fff;
    border-radius: 0.34rem;
    margin: 0 0.05rem;
    img {
      display: inline-block;
      width: 0.23rem;
      height: 0.23rem;
      margin: 0 0.1rem 0 0.2rem;
      vertical-align: middle;
    }
  }
  .spot {
    color: #999999;
    font-size: 0.28rem;
    padding-left: 0.15rem;
    // white-space: nowrap;
  }
  .bottoms {
    display: flex;
    flex-direction: row;
    padding: 0 0.15rem;
  }
  .voice {
    background-color: #289cff;
  }
  .gothere {
    background-color: #ffb128;
  }
}
.bottom-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  .routeline {
    display: block;
    background: #fff;
    padding: 0.4rem 0.3rem;
    font-size: 0;
    .title {
      font-size: 0.34rem;
      height: 0.6rem;
      overflow: hidden;
      line-height: 0.6rem;
      text-align: left;
      color: #aaaaaa;
      span {
        font-size: 0.3rem;
        color: #aaa;
        float: right;
        display: inline-block;
        padding-right: 0.1rem;
        margin-top: -0.1rem;
      }
    }
    .item {
      display: inline-block;
      line-height: 0.8rem;
      font-size: 0.36rem;
    }
    .time {
      margin-right: 0.4rem;
    }
    .spendTime,
    .distance {
      display: inline-block;
      line-height: 0.8rem;
    }
    .guide {
      float: right;
      display: block;
      width: 2.5rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #ea8677;
      color: #fff;
      text-decoration: none;
      border: 0;
      border-radius: 0.5rem;
      font-size: 0.34rem;
      box-sizing: border-box;
      text-align: center;
    }
  }
  .sPointContainer {
    display: block;
    height: 0.8rem;
    position: relative;
    background: #fff;
    padding-left: 1rem;
    font-size: 0;
    .play,
    .close {
      position: absolute;
      line-height: 0.6rem;
    }
    .play {
      width: 0.6rem;
      height: 0.6rem;
      left: 0.2rem;
      top: 0.1rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: inline-block;
      font-size: 0.28rem;
      color: #000;
      line-height: 0.8rem;
      padding-right: 0.5rem;
      position: relative;
    }
    .icon-right {
      position: absolute;
      right: 0;
      top: 0.3rem;
      width: 0.13rem;
      height: 0.25rem;
      background: url('../../assets/icon-right.png') right center no-repeat;
    }
    .close {
      right: 0.2rem;
      top: 0.35rem;
      width: 0.15rem;
      height: 0.15rem;
      padding: 0.3rem 0.1rem;
      transform: translateY(-40%);
    }
  }
}
</style>