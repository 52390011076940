/*
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-04-08 14:24:36
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-08 14:38:28
 */

/* eslint-disable */
export function checkURL(URL) {
  let str = URL;
  const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  const objExp = new RegExp(Expression);
  if (objExp.test(str) == true) {
    return true;
  }
  return false;
}
